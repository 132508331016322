import React from 'react';
import type { FC } from 'react';
import { graphql, Link } from 'gatsby';
import { Layout } from '../components/layout';
import type { ArticlePageContext } from '../../gatsby-node';
import type { ArticleTemplateQuery } from '../../types/graphql-types';

interface PageProps {
  data: ArticleTemplateQuery;
  pageContext: ArticlePageContext;
}

export const query = graphql`
  query ArticleTemplate($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "YYYY/MM/DD HH:mm:ss", locale: "ja-JP")
      }
    }
  }
`;

const Page: FC<PageProps> = ({ data }) => {
  const title = data.markdownRemark?.frontmatter?.title ?? undefined;
  console.log('title:' + title);
  return (
    <>
      <Layout articleTitle={title}>
        <h2 className="article-title">{title}</h2>
        {data.markdownRemark?.frontmatter?.date && (
          <p className="article-date">
            {data.markdownRemark.frontmatter.date} 投稿
          </p>
        )}
        <hr />
        <div
          className="article-body"
          dangerouslySetInnerHTML={{ __html: data.markdownRemark?.html ?? '' }}
        />
        <p>
          <Link to="/">トップへ戻る</Link>
        </p>
      </Layout>
    </>
  );
};
export default Page;
